import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">



<path d="M1777 8004 c-2 -5 -8 -24 -12 -41 -6 -32 -8 -33 -55 -33 -38 0 -50
-4 -54 -17 -3 -10 -39 -144 -80 -298 -67 -250 -138 -518 -265 -992 -23 -84
-41 -154 -41 -157 0 -8 711 -7 956 1 l202 6 37 136 c20 75 44 162 52 194 l16
57 386 0 c222 0 401 -4 421 -10 57 -16 102 -69 108 -128 5 -60 -11 -142 -43
-207 -13 -27 -23 -49 -22 -50 21 -4 637 12 642 17 4 4 8 329 9 723 1 628 3
715 16 716 8 1 -10 9 -40 19 -67 23 -39 25 49 5 35 -9 66 -14 68 -12 5 6 -82
28 -152 38 -33 5 -80 13 -105 18 -58 11 -2086 26 -2093 15z m2233 -74 c8 -5
11 -10 5 -10 -5 0 -17 5 -25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z"/>
<path d="M11048 7970 c-23 -22 -50 -40 -62 -40 -16 0 -135 -120 -430 -430
-225 -236 -411 -430 -415 -430 -3 0 -25 48 -48 108 -54 139 -240 600 -255 632
-6 14 -25 61 -42 105 l-31 80 -335 1 c-184 1 -320 -1 -303 -3 l33 -4 2 -632
c3 -582 4 -632 20 -631 15 1 14 -2 -3 -32 -10 -18 -14 -36 -10 -39 5 -2 11 5
14 17 8 31 16 19 10 -14 -4 -23 -8 -26 -28 -18 -22 8 -23 7 -15 -20 12 -40 29
-70 30 -50 0 9 -5 21 -11 27 -6 6 -9 17 -6 25 4 8 8 4 13 -11 7 -23 8 -23 15
-5 5 13 7 8 6 -17 -1 -21 -6 -35 -11 -32 -4 3 -5 -5 -1 -18 17 -53 15 -51 -15
11 -17 35 -33 76 -37 90 -3 14 -9 27 -13 30 -4 3 -10 16 -14 30 -6 19 -5 21 3
10 6 -9 11 -10 11 -3 0 6 3 20 6 30 5 15 4 16 -6 3 -9 -12 -10 -9 -5 17 4 18
2 34 -4 38 -6 3 -11 11 -11 16 0 6 5 7 11 3 7 -4 10 1 7 11 -1 10 -9 20 -15
23 -17 5 -21 -46 -8 -98 12 -45 -11 0 -60 120 -26 63 -55 134 -66 157 -10 23
-19 45 -19 49 0 4 -18 48 -40 98 -53 122 -171 408 -174 421 -2 6 -8 21 -14 35
-16 37 -97 230 -112 268 -13 33 -13 33 -74 30 -60 -3 -61 -3 -64 -34 -2 -18 4
-49 14 -70 9 -22 35 -86 59 -144 23 -58 70 -170 103 -250 33 -80 111 -271 175
-425 63 -154 139 -338 169 -409 l53 -129 900 2 900 2 51 53 c27 30 102 109
165 176 170 182 181 194 313 334 66 71 132 140 145 155 13 14 58 62 100 106
42 44 132 141 201 215 105 113 262 281 280 300 84 85 170 182 170 190 0 7
-195 10 -591 10 l-591 0 -40 -40z m-1940 -1201 c-3 -8 -7 -3 -11 10 -4 17 -3
21 5 13 5 -5 8 -16 6 -23z"/>
<path d="M4981 7652 c-40 -152 -95 -356 -121 -452 -99 -367 -110 -409 -150
-559 -22 -84 -40 -160 -40 -168 0 -11 11 -13 47 -11 l48 3 22 90 c12 50 61
234 108 410 47 176 101 376 120 445 115 435 135 510 135 515 0 3 -21 5 -47 5
l-48 0 -74 -278z"/>
<path d="M7310 7888 c-6 -24 -34 -126 -61 -228 -86 -316 -127 -471 -153 -570
-14 -52 -43 -162 -65 -245 -23 -82 -41 -156 -41 -163 0 -9 13 -12 47 -10 l47
3 27 105 c14 58 45 175 69 260 23 85 62 229 86 320 24 91 57 215 73 275 16 61
41 152 55 203 l25 92 -49 0 -49 0 -11 -42z"/>
<path d="M4250 7886 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M9111 7024 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9016 6947 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M9050 6875 c0 -18 19 -40 26 -32 4 4 0 15 -9 24 -9 10 -17 13 -17 8z"/>
<path d="M2618 6345 c-3 -3 -25 -9 -48 -13 -50 -8 -67 -32 -24 -32 l27 0 -6
-207 c-4 -115 -10 -232 -13 -261 l-7 -53 49 19 c27 11 50 27 52 36 3 11 -1 14
-14 9 -15 -6 -16 2 -12 93 5 110 19 151 60 173 47 27 53 9 53 -165 0 -85 3
-154 6 -154 4 0 27 9 53 20 48 22 60 40 27 40 -27 0 -28 9 -23 147 7 175 -16
203 -122 149 l-48 -24 5 91 c3 51 6 101 8 111 4 18 -12 32 -23 21z"/>
<path d="M7523 6320 c-74 -17 -121 -80 -108 -144 7 -40 15 -49 103 -120 40
-32 82 -70 92 -84 26 -32 25 -73 -1 -106 -28 -36 -74 -35 -127 4 -23 17 -44
30 -47 30 -2 0 -11 -13 -19 -29 l-15 -29 49 -21 c87 -38 160 -24 214 39 20 24
26 43 26 77 0 60 -23 96 -95 149 -131 96 -150 130 -100 179 38 39 138 30 106
-9 -8 -9 -9 -16 -3 -16 6 0 17 -2 24 -5 9 -4 22 9 36 34 l22 39 -50 11 c-57
12 -61 12 -107 1z"/>
<path d="M2050 6306 c0 -9 8 -16 19 -16 14 0 20 -10 25 -37 10 -62 7 -335 -5
-397 -5 -31 -9 -61 -7 -66 2 -4 20 -1 41 7 l37 15 0 119 0 119 28 0 c15 0 45
9 67 19 106 48 137 175 56 227 -20 14 -54 19 -143 22 -104 4 -118 3 -118 -12z
m197 -35 c76 -47 44 -174 -47 -183 l-35 -3 -3 103 -3 102 28 0 c15 0 42 -9 60
-19z"/>
<path d="M5262 6303 c2 -11 14 -19 29 -21 24 -3 27 -8 33 -55 8 -68 8 -311 -1
-360 -5 -36 -8 -39 -34 -35 -23 4 -29 1 -29 -15 0 -18 7 -19 163 -15 151 4
165 5 212 30 63 33 98 69 127 133 46 101 19 243 -56 300 -50 38 -142 55 -304
55 -131 0 -143 -1 -140 -17z m319 -38 c86 -31 132 -101 132 -205 1 -139 -83
-220 -227 -220 -104 0 -96 -18 -96 226 l0 214 74 0 c40 0 93 -7 117 -15z"/>
<path d="M4312 6293 c-14 -27 -3 -43 28 -43 31 0 55 23 46 45 -8 21 -63 20
-74 -2z"/>
<path d="M9040 6295 c-21 -25 -4 -47 32 -43 28 2 34 8 36 31 3 24 0 27 -26 27
-16 0 -35 -7 -42 -15z"/>
<path d="M6443 6284 c-32 -13 -33 -17 -33 -69 l0 -55 -35 0 c-29 0 -35 -4 -35
-21 0 -18 4 -21 30 -16 l29 6 -6 -144 c-5 -125 -4 -147 11 -169 10 -17 26 -26
42 -26 26 0 98 33 108 49 11 17 -25 21 -54 6 -27 -14 -30 -14 -40 4 -11 22
-13 127 -4 219 l7 60 48 7 c39 6 49 11 49 26 0 20 -8 20 -74 2 -17 -5 -18 0
-11 66 4 39 5 71 4 70 -2 0 -19 -7 -36 -15z"/>
<path d="M3915 6170 c-3 -5 -24 -14 -46 -20 -39 -11 -51 -37 -15 -34 14 2 16
-10 15 -85 0 -47 -4 -121 -7 -163 -4 -43 -4 -78 1 -78 33 0 97 25 97 37 0 11
-7 14 -20 10 -20 -5 -21 -3 -14 96 7 112 22 157 59 177 20 11 27 11 40 0 12
-10 15 -40 15 -166 0 -85 3 -154 7 -154 24 0 93 35 93 48 0 9 -7 13 -20 9 -19
-5 -20 0 -20 142 0 173 -5 188 -66 178 -21 -4 -51 -16 -66 -27 l-28 -21 0 25
c0 27 -15 43 -25 26z"/>
<path d="M6031 6156 c-13 -14 -36 -30 -51 -36 -19 -8 -27 -17 -23 -27 6 -15
10 -15 80 11 61 23 73 14 73 -50 0 -51 -1 -54 -19 -44 -22 11 -71 13 -96 4
-42 -16 -62 -145 -29 -192 20 -28 65 -29 110 -1 l34 21 0 -30 0 -29 55 21 c37
14 55 27 55 38 0 15 -4 16 -25 8 l-25 -10 0 144 c0 80 -3 151 -6 160 -4 10
-18 16 -39 16 -19 0 -37 5 -40 10 -10 17 -30 11 -54 -14z m53 -182 c12 -4 16
-20 16 -55 0 -38 -4 -51 -22 -63 -12 -9 -27 -16 -34 -16 -28 0 -46 104 -22
128 13 13 37 15 62 6z"/>
<path d="M6791 6156 c-13 -14 -36 -30 -52 -37 -32 -13 -34 -35 -3 -32 10 0 38
8 61 17 23 9 49 13 57 10 17 -6 26 -49 19 -86 -5 -24 -8 -26 -30 -17 -13 5
-40 9 -59 9 -28 0 -38 -6 -54 -32 -23 -38 -27 -119 -7 -158 17 -33 66 -38 113
-11 l34 20 0 -28 0 -28 55 21 c37 14 55 27 55 38 0 15 -4 16 -25 8 l-25 -10 0
144 c0 80 -3 151 -6 160 -4 10 -18 16 -39 16 -19 0 -37 5 -40 10 -10 17 -30
11 -54 -14z m60 -187 c39 -21 1 -129 -46 -129 -15 0 -35 43 -35 76 0 56 34 79
81 53z"/>
<path d="M8326 6172 c-4 -6 -24 -17 -46 -23 -26 -8 -37 -16 -33 -26 3 -8 12
-12 20 -9 23 9 25 -16 15 -171 -6 -81 -9 -150 -6 -152 2 -3 25 2 51 10 50 16
70 39 33 39 -22 0 -22 2 -18 125 3 100 7 129 21 142 13 14 21 14 42 5 22 -10
26 -9 39 17 9 16 12 31 9 35 -9 9 -66 7 -82 -3 -10 -7 -19 -5 -26 6 -8 11 -14
13 -19 5z"/>
<path d="M3093 6159 c-64 -19 -103 -94 -103 -199 0 -100 54 -166 135 -164 94
2 147 68 153 189 4 83 -15 127 -67 162 -36 24 -65 27 -118 12z m91 -62 c18
-24 21 -43 21 -116 0 -111 -17 -144 -73 -139 -52 4 -76 49 -76 138 2 128 72
192 128 117z"/>
<path d="M3510 6147 c-48 -31 -72 -78 -77 -150 -10 -122 35 -197 119 -197 46
0 128 42 128 65 0 26 -22 27 -49 1 -59 -57 -122 -16 -129 83 l-4 58 58 7 c106
12 114 15 114 42 0 30 -26 96 -43 106 -24 15 -84 8 -117 -15z m88 -50 c17 -35
10 -43 -45 -51 -49 -7 -54 3 -27 55 19 38 53 36 72 -4z"/>
<path d="M4340 6160 c-8 -5 -25 -10 -37 -10 -13 0 -23 -4 -23 -10 0 -5 0 -13
0 -17 0 -5 6 -8 13 -8 20 0 24 -42 19 -193 l-5 -142 51 22 c32 14 52 29 52 40
0 11 -6 15 -20 11 -19 -5 -20 -1 -19 103 0 60 4 133 8 162 6 44 5 52 -8 52 -9
0 -23 -5 -31 -10z"/>
<path d="M4582 6157 c-30 -8 -42 -16 -40 -26 2 -9 11 -16 20 -16 17 0 63 -56
91 -111 15 -30 15 -31 -44 -113 -32 -45 -59 -84 -59 -87 0 -2 17 -4 38 -4 36
0 38 2 62 62 14 34 30 63 36 65 7 2 29 -27 49 -64 21 -37 39 -69 40 -71 2 -2
23 2 48 9 32 8 44 15 40 25 -3 8 -13 14 -22 14 -9 0 -36 32 -64 76 l-47 75 59
78 60 78 -38 12 c-51 15 -51 15 -51 -15 0 -30 -39 -114 -53 -114 -5 0 -23 31
-40 70 -18 38 -34 69 -37 68 -3 0 -24 -6 -48 -11z"/>
<path d="M7945 6161 c-32 -14 -75 -65 -85 -102 -14 -49 -12 -154 4 -192 25
-62 98 -84 169 -53 55 25 70 42 58 64 -11 20 -12 20 -40 -9 -64 -64 -127 -22
-133 90 l-3 45 40 7 c22 4 61 10 88 14 l47 6 -6 42 c-9 63 -33 92 -80 94 -21
1 -48 -2 -59 -6z m65 -50 c19 -37 13 -61 -17 -61 -16 0 -38 -3 -50 -6 -29 -8
-30 10 -3 54 22 36 54 42 70 13z"/>
<path d="M8593 6152 c-44 -17 -58 -42 -24 -42 19 0 46 -60 107 -237 15 -46 33
-83 39 -83 7 0 18 19 25 43 7 23 39 102 71 176 33 74 59 136 59 137 0 3 -70
24 -80 24 -3 0 -4 -17 -2 -38 3 -35 -37 -175 -56 -194 -10 -10 -70 146 -74
191 -4 41 -10 43 -65 23z"/>
<path d="M9055 6158 c-16 -6 -36 -12 -42 -14 -7 -2 -13 -11 -13 -19 0 -12 6
-15 20 -10 21 7 21 4 18 -159 -2 -102 1 -166 7 -166 5 0 29 7 52 16 30 12 43
23 43 36 0 16 -3 17 -21 7 l-21 -11 4 166 c2 110 0 166 -7 165 -5 -1 -23 -6
-40 -11z"/>
<path d="M9373 6150 c-90 -55 -110 -245 -33 -322 33 -33 91 -38 146 -12 44 21
57 38 49 64 -6 20 -8 20 -41 -10 -43 -37 -68 -38 -99 -4 -30 32 -39 78 -32
152 10 94 72 148 95 83 12 -34 15 -36 43 -21 25 14 24 45 -1 70 -26 26 -85 26
-127 0z"/>
<path d="M9775 6161 c-29 -13 -62 -51 -80 -93 -20 -49 -19 -147 1 -198 29 -72
111 -91 191 -45 46 27 48 31 33 55 -8 12 -14 10 -38 -13 -63 -60 -127 -18
-133 88 l-3 50 39 7 c22 3 62 9 88 13 l49 6 -7 42 c-10 63 -34 92 -81 94 -21
1 -48 -2 -59 -6z m65 -50 c16 -32 12 -61 -9 -61 -10 0 -33 -3 -51 -6 -30 -7
-32 -6 -25 17 19 65 63 90 85 50z"/>
<path d="M10140 6160 c-31 -12 -60 -58 -60 -95 0 -27 23 -54 113 -133 38 -33
48 -67 25 -90 -18 -18 -39 -15 -79 12 l-36 25 -12 -25 c-15 -33 -9 -41 42 -55
78 -21 157 25 157 93 0 37 -24 84 -51 99 -37 20 -99 81 -99 98 0 41 67 59 77
21 3 -11 11 -20 19 -20 14 0 49 58 41 67 -10 10 -113 12 -137 3z"/>
<path d="M1011 5504 c-6 -22 -17 -64 -25 -94 -8 -30 -53 -201 -101 -380 -209
-778 -222 -832 -211 -836 6 -2 245 -3 531 -2 l520 3 11 35 c11 32 15 35 57 38
54 4 40 -27 122 282 31 118 65 244 75 280 10 36 40 147 66 248 l48 182 405 0
c542 0 743 23 982 111 81 29 260 117 294 143 18 14 -106 15 -1305 16 -729 0
-1355 3 -1392 6 l-66 7 -11 -39z"/>
<path d="M4416 5532 c-4 -6 -153 -555 -212 -782 -8 -30 -44 -163 -79 -296 -36
-132 -65 -246 -65 -252 0 -9 126 -12 529 -12 l529 0 11 31 c22 63 54 59 -468
61 l-478 3 34 130 c18 72 36 137 38 145 2 8 22 83 44 165 42 160 109 412 143
535 11 41 26 100 33 130 7 30 19 74 27 97 13 36 13 43 1 47 -22 9 -81 7 -87
-2z"/>
<path d="M6126 5533 l552 -3 -33 -128 c-19 -70 -48 -179 -65 -242 -18 -63 -47
-173 -66 -245 -19 -71 -69 -260 -112 -420 -43 -159 -77 -293 -75 -297 2 -5
240 -8 528 -8 508 0 525 1 535 19 5 11 10 32 10 46 l0 27 -480 -8 c-264 -4
-480 -3 -480 1 0 4 16 65 35 134 19 69 47 176 63 236 97 363 176 656 205 762
19 68 32 125 29 128 -3 3 -273 4 -601 3 l-596 -3 551 -2z"/>
<path d="M8697 5518 c-13 -13 -111 -115 -218 -228 -302 -320 -347 -368 -459
-485 -262 -274 -565 -597 -568 -606 -2 -5 231 -9 598 -9 l602 0 42 43 41 42
-582 5 -581 5 106 111 c59 61 171 178 249 260 77 82 163 172 190 199 77 81
376 395 427 450 26 28 85 89 132 136 64 66 80 88 68 93 -21 8 -20 9 -47 -16z"/>
<path d="M9088 5523 c28 -2 52 -8 52 -13 0 -5 8 -7 18 -5 24 4 53 -14 35 -22
-7 -2 -13 -11 -13 -19 0 -8 6 -14 13 -15 6 0 2 -4 -10 -9 -23 -9 -23 -10 -23
-245 0 -232 0 -235 21 -235 l21 0 -21 -36 c-26 -44 -27 -75 -2 -94 23 -16 18
-30 -9 -30 -29 0 -54 -44 -47 -85 4 -27 3 -33 -9 -28 -8 3 -13 12 -10 19 10
25 -9 14 -21 -12 -16 -35 -6 -50 18 -28 10 9 19 12 19 6 0 -6 25 16 57 49 31
32 53 62 48 67 -4 4 -10 2 -12 -4 -2 -6 -14 -9 -27 -7 -16 3 -25 -1 -30 -14
-3 -10 -9 -24 -12 -30 -3 -7 -1 -13 4 -13 5 0 12 9 15 20 3 11 10 20 16 20 15
0 -15 -46 -35 -54 -20 -8 -18 37 4 63 9 12 27 23 40 24 12 1 22 4 22 7 0 4 7
7 15 7 8 0 12 -4 9 -9 -3 -4 -1 -8 5 -8 5 0 14 7 18 15 5 8 54 63 109 122 56
59 122 130 147 158 25 27 47 48 50 45 2 -3 24 -50 49 -105 25 -55 86 -190 136
-300 50 -110 124 -274 165 -365 l74 -165 593 -3 592 -2 -7 22 c-20 65 -22 63
46 66 35 1 64 4 66 5 2 3 -29 84 -150 382 -35 88 -90 223 -121 300 -31 77 -61
149 -66 160 -15 30 -151 367 -157 388 -5 16 -55 17 -877 15 -479 -1 -847 -3
-818 -5z m115 -637 c6 -15 -1 -26 -15 -26 -4 0 -8 9 -8 20 0 23 15 27 23 6z
m54 -22 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-37 -24 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m40 -4 c0
-3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"/>
<path d="M9125 5470 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M9125 4950 c-4 -6 -1 -18 6 -26 10 -13 10 -17 -1 -24 -8 -5 -11 -12
-8 -16 4 -3 14 1 23 10 15 15 15 17 1 32 -9 8 -14 20 -11 25 4 5 4 9 1 9 -3 0
-8 -5 -11 -10z"/>
<path d="M9060 4615 c-13 -14 -21 -27 -18 -30 2 -3 17 9 33 25 16 17 24 30 19
30 -6 0 -21 -11 -34 -25z"/>
<path d="M8966 4552 c-3 -3 -6 -10 -6 -16 0 -6 4 -4 10 4 5 8 12 11 16 7 4 -4
-1 -16 -11 -27 -10 -11 -16 -22 -13 -25 3 -3 16 9 30 27 l25 33 -23 1 c-12 1
-25 -1 -28 -4z"/>
<path d="M8849 4383 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M8766 4295 c-11 -8 -15 -15 -9 -15 6 0 16 7 23 15 16 19 11 19 -14 0z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
